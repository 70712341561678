"use client";
import React, { Children, PropsWithChildren, useCallback } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Keyboard, Navigation } from "swiper/modules";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";

interface ICarouselClientPortionProps {
	readonly smallScreenBottomSummaries: React.ReactNode;
}

const CarouselClientPortion: React.FC<PropsWithChildren<ICarouselClientPortionProps>> = ({
	children,
	smallScreenBottomSummaries,
}) => {
	const swiperRef = React.useRef<SwiperRef | null>(null);
	const [currentSlideIndex, setCurrentSlideIndex] = React.useState(0);
	const [currentSlideOpacity, setCurrentSlideOpacity] = React.useState(true);
	const deck = Children.toArray(children).map((child, index) => {
		return <SwiperSlide key={`swiper_slide_${index}`}>{child}</SwiperSlide>;
	});
	const smallScreenBottomSummariesArray = Children.toArray(smallScreenBottomSummaries);
	const smallScreenBottomSummariesItem = smallScreenBottomSummariesArray[currentSlideIndex];
	const smallScreenBottomSummariesElement = smallScreenBottomSummariesItem ? (
		<div
			className={`lg:hidden transition-opacity md:transition-none ease-in ${
				currentSlideOpacity ? "opacity-100 duration-300" : "opacity-0 duration-0"
			}`}
		>
			{smallScreenBottomSummariesItem}
		</div>
	) : null;
	const updateBottomSummary = useCallback(() => {
		if (!swiperRef.current) {
			return;
		} else if (swiperRef.current.swiper.realIndex === currentSlideIndex) {
			return;
		}
		setCurrentSlideOpacity(false);
		const changeIndexTimer = setTimeout(() => {
			if (!swiperRef.current) {
				return;
			}
			setCurrentSlideIndex(swiperRef.current.swiper.realIndex);
		}, 2);
		const fullOpacityTimer = setTimeout(() => {
			setCurrentSlideOpacity(true);
		}, 4);
		return () => {
			clearTimeout(changeIndexTimer);
			clearTimeout(fullOpacityTimer);
		};
	}, [currentSlideIndex, swiperRef]);
	return (
		<>
			<Swiper
				ref={swiperRef}
				navigation={true}
				keyboard={{ enabled: true, onlyInViewport: true, pageUpDown: false }}
				loop={true}
				onSlideChange={() => {
					updateBottomSummary();
				}}
				autoplay={{
					delay: 30000,
					disableOnInteraction: false,
				}}
				modules={[Navigation, Keyboard, Autoplay]}
			>
				{deck}
			</Swiper>
			{smallScreenBottomSummariesElement}
		</>
	);
};
export default CarouselClientPortion;
